import React from "react";
import heroImage from "../../assets/hero-image.png";

function Hero() {
  return (
    <section className="relative">
      <div
        className="container
        flex
        flex-col-reverse
        lg:flex-row
        items-center
        gap-12
        mt-14
        lg:mt-28"
      >
        {/* content */}
        <div
          className="flex
            flex-1
            flex-col
            items-center
            lg:items-start"
        >
          <h2
            className="text-blue-800
            dark:text-blue-500
            text-3xl
            md:text-4
            lg:text-5xl
            text-center
            lg:text-left
            mb-6"
          >
            Tu tarjeta de presentación definitiva
          </h2>
          <p
            className="
            text-lg
            text-center
            lg:text-left mb-6"
          >
            Comparte tu información de contacto de manera profesional desde tu
            celular. Dile adiós a las tarjetas de cartón.
          </p>
          <div
            className="flex
          justify-center
          flex-wrap
          gap-6"
          >
            <button
              className="btn 
              btn-accent 
              hover:bg-white 
              hover:dark:bg-neutral-200 
              hover:dark:text-neutral-800"
            >
              Call to action
            </button>
            <button
              className="btn
              btn-white
              hover:bg-indigo-600 
              hover:text-white"
            >
              Call to action 2
            </button>
          </div>
        </div>
        {/* image */}
        <div
          className="flex
          justify-center
          flex-1
          mb-10
          md:mb-16
          lg:mb-0
          z-10"
        >
          <img
            src={heroImage}
            alt="Tarjeta Ejecutiva Digital"
            className="rounded-xl
            w-5/6
            h-5/6
            sm:w-3/4
            sm:h-3/4
            md:w-full
            md:h-full
            lg:px-10
            lg:ml-28"
          />
        </div>
      </div>
      <div
        className="hidden
      md:block
      overflow-hidden
      bg-indigo-800
      rounded-l-full
      absolute
      h-80
      w-2/4
      top-44
      right-0
      lg:-bottom-28"
      ></div>
    </section>
  );
}

export default Hero;
