const data = [
  {
    id: "1",
    slug: "ruben-robles",
    banner:
      "https://tarjetaejecutiva.com.mx/wp-content/uploads/2023/10/banner-upper-tarjeta-ejecutiva-c.png",
    avatar:
      "https://tarjetaejecutiva.com.mx/wp-content/uploads/2024/08/Ru-e1724395758112.jpg",
    name: "Rubén Robles",
    jobPosition: "Ser Humano",
    buttons: [
      { id: 1, type: "website", value: "https://robledigital.com" },
      {
        id: 2,
        type: "location",
        value: "https://maps.app.goo.gl/bmwVgdrToA1jHD889",
      },
      {
        id: 3,
        type: "whatsapp",
        value: {
          number: "5560809973",
          message: "Hola estoy interesado conocer sus soluciones.",
        },
      },
      { id: 4, type: "phone", value: "5560809973" },
      {
        id: 5,
        type: "email",
        value: {
          address: "ruben@robledital.com",
          subject: "Me interesa conectar",
          message: "Hola estoy interesado conocer sus soluciones.",
        },
      },
      { id: 6, type: "facebook", value: null },
      { id: 7, type: "twitter", value: "https://x.com/" },
      { id: 8, type: "instagram", value: "https://www.instagram.com/rubsrc/" },
      { id: 9, type: "tiktok", value: undefined },
      {
        id: 10,
        type: "linkedin",
        value: "https://www.linkedin.com/in/ruben-robles-cortes/",
      },
      {
        id: 11,
        type: "share",
        value: {
          qrCode: "https://mitedi.com/uploads/RubenRobles-QR.png",
          vcfQrCode: "https://mitedi.com/uploads/RubenRobles-VCF-QR.png",
        },
        onClick: "toggleModal",
      },
      {
        id: 12,
        type: "vcfFile",
        value: "https://mitedi.com/uploads/RubenRobles.vcf",
      },
    ],
    slogan: "Software Engineer. Entrepeneur. Husband. Dad.",
    colors: {
      text: {
        name: "#333333",
        jobPosition: "#3730a3",
        slogan: "#fff",
      },
      background: {
        top: "#fff",
        bottom: "#3730a3",
      },
      buttons: {
        primary: "#333",
        hover: "#0056b3", // no hover color change for now..
        icon: "#fff",
      },
    },
  },
  {
    id: "2",
    slug: "nombre-apellido",
    banner:
      "https://tarjetaejecutiva.com.mx/wp-content/uploads/2023/10/banner-upper-tarjeta-ejecutiva-c.png",
    avatar:
      "https://tarjetaejecutiva.com.mx/wp-content/uploads/2023/10/demo-profile-pic.png",
    name: "Nombre Apellido Muy Largo",
    jobPosition: "Ser Humano Con Alma Buena Y Corazón Puro",
    buttons: [
      { id: 1, type: "website", value: "https://robledigital.com" },
      {
        id: 2,
        type: "location",
        value: "https://maps.app.goo.gl/bmwVgdrToA1jHD889",
      },
      {
        id: 3,
        type: "whatsapp",
        value: {
          number: "5560809973",
          message: "Hola estoy interesado conocer sus soluciones.",
        },
      },
      { id: 4, type: "phone", value: "5560809973" },
      {
        id: 5,
        type: "email",
        value: {
          address: "ruben@robledital.com",
          subject: "Me interesa conectar",
          message: "Hola estoy interesado conocer sus soluciones.",
        },
      },
      { id: 6, type: "facebook", value: "https://www.facebook.com/" },
      { id: 7, type: "twitter", value: "https://x.com/" },
      { id: 8, type: "instagram", value: "https://www.instagram.com/rubsrc/" },
      { id: 9, type: "tiktok", value: "https://www.tiktok.com/" },
      {
        id: 10,
        type: "linkedin",
        value: "https://www.linkedin.com/in/ruben-robles-cortes/",
      },
      {
        id: 11,
        type: "share",
        value: {
          qrCode: "https://mitedi.com/uploads/RubenRobles-QR.png",
          vcfQrCode: "https://mitedi.com/uploads/RubenRobles-VCF-QR.png",
        },
        onClick: "toggleModal",
      },
      {
        id: 12,
        type: "vcfFile",
        value: "https://mitedi.com/uploads/RubenRobles.vcf",
      },
    ],
    slogan:
      "loremLorem ipsum dolor sit amet consectetur adipisicing elit. Hic mollitia consectetur a, laboriosam consequatur, similique temporibus, recusandae magnam laborum odit magni repellendus harum debitis dolore tempore sit sequi sunt et?",
    colors: {
      text: {
        name: "#fff",
        jobPosition: "#0056b3",
        slogan: "#fff",
      },
      background: {
        top: "#333333",
        bottom: "#0056b3",
      },
      buttons: {
        primary: "#3730a3",
        hover: "#3730a3",
        icon: "#fff",
      },
    },
  },
];

export default data;
