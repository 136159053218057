import React, { useState } from "react";
import tediLogo from "../../assets/tedi-logo-new.png";

const Navbar = () => {
  // State to manage the expanded state of the navbar
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to handle the click event
  const toggleMenu = () => {
    setIsExpanded((prevState) => !prevState);
  };

  return (
    <nav className="mx-auto p-1 bg-blue-500">
      <div
        className="container
        mx-auto
        flex
        items-center
        justify-between
        px-6"
      >
        <a
          href="/"
          aria-label="Go to homepage"
          className="focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-sm
          ring-offset-2
          ring-offset-blue-500
          lg:absolute
          lg:left-1/2
          lg:-translate-x-1/2
          z-50
          hover:opacity-75
          transition-opacity"
        >
          <img
            src={tediLogo}
            width="200"
            alt="TEDI Logo"
            className="w-28 md:w-40 lg:w-40"
          />
        </a>
        <button
          aria-expanded={isExpanded}
          onClick={toggleMenu}
          className="lg:hidden
          focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-sm
          ring-offset-2
          ring-offset-blue-500
          text-neutral-900
          hover:text-neutral-600
          transition-colors
          m-3
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <div
          role="menubar"
          className={`${isExpanded ? "flex" : "hidden"} 
          flex-col
          gap-4
          absolute
          right-0
          left-0
          top-16
          z-30
          bg-blue-500
          shadow-xl
          text-center
          text-lg
          p-6
          items-center
          lg:flex
          lg:flex-row
          lg:static
          lg:shadow-none
          lg:justify-between
          lg:w-full`}
        >
          <a
            role="menuitem"
            href="/"
            className="
          py-1
          px-6
          dark:text-neutral-100
          focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-sm
          ring-offset-2
          ring-offset-blue-500
          text-neutral-900
          hover:text-neutral-600
          transition-colors"
          >
            Tarjetas Digitales
          </a>
          <a
            role="menuitem"
            href="/"
            className="
          py-1
          px-6
          dark:text-neutral-100
          focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-sm
          ring-offset-2
          ring-offset-blue-500
          text-neutral-900
          hover:text-neutral-600
          transition-colors
          lg:mr-auto"
          >
            Tarjetas NFC
          </a>
          <a
            role="menuitem"
            href="/"
            className="
          py-1
          px-6
          dark:text-neutral-100
          focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-sm
          ring-offset-2
          ring-offset-blue-500
          text-neutral-900
          hover:text-neutral-600
          transition-colors"
          >
            Acceder
          </a>
          <a
            role="menuitem"
            href="/"
            className="
          py-1
          px-6
          bg-indigo-600
          text-white
          shadow-xl
          hover:shadow-none
          transition-shadow
          focus:outline-none
          focus-visible:ring-2
          ring-neutral-900
          rounded-md
          ring-offset-2
          ring-offset-blue-500"
          >
            Crea Tu Tarjeta
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
